<template>
  <v-container fluid fill-height class="d-flex align-center justify-center">
    <v-card style="max-width:600px; width:100%;">
      <v-card-title>First Time Login</v-card-title>
      <v-card-text>
        <AError :api="api"></AError>
        <v-form>
          <v-text-field
            required
            v-model="form.email"
            :label="$t('model.prop.email')"
            @keyup.native.enter="fetch"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          text
          :to="{name:'PageGuestHome'}"
        >Cancel</v-btn>
        <v-btn
          :loading="api.isLoading"
          color="primary"
          @click="fetch()"
        >Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Api from '@/objects/api'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:{
    email: {
      type: String,
    }
  },
  data: () => ({
    form:{
      email:null,
    },
    //BOC:[api]
    api: new Api(),
    //EOC
  }),
  created() {
    //BOC
    if(this.email) this.form.email = this.$_.clone(this.email)
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(`${this.$service.sso}/v1/en/auth/login/email/verify`);
    this.api.setCallbackCompleted((resp)=>{
      this.$router.push({ name: 'PageGuestLoginFirstTimeSetPassword', params:{
        email: resp.email,
      }});
    })
  },
  mounted() {
    //
  },
  methods: {
    fetch() {
      this.api.setParams(this.form);
      this.api.fetch()
    },
  },
}
</script>